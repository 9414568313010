<template>
    <div>
      <div v-if="loading" class="loading-wrap">
        <div class="lds-ellipsis">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
        <div class="row">
            <div class="col-md-4">
                <!--ЗАРЛАГДСАН АЖЛУУД-->
                <div class="left">
                    <div class="job_news_title">
                        <div class="img"><img src="../../assets/paint_icon.svg" alt="Icon"></div>
                        <div class="text_line_title">
                            <!--              <span>{{ 'ajliin_bairnii_zar' | translate }}</span>-->
                            <span>{{ 'brigad_ajliin_bairnii_zar' | translate }}</span>
                            <svg class="svg_text_line">
                                <line x1="80" style="stroke:#EFBC11; stroke-width:3;"/>
                            </svg>
                            <a href="javascript:void(0)" @click="cleanFilter" class="clean_button">
                                <span>{{ 'clear' | translate }}</span>
                            </a>
                        </div>
                    </div>

                    <div class="tab_main">
                        <p>{{ 'brigad' | translate }}</p>
                        <Select class="job_select" v-model="selectBrigad" :placeholder="'brigadaar_haih' | translate " filterable>
                            <Option v-for="(brigad, index) in select_ds_brigad_view" :value="brigad.id" :key="index">
                               <div v-if="language.short == 'mn'"> {{ brigad.ner_mn }}</div>
                              <div v-else>{{ brigad.ner_en }}</div>
                            </Option>
                        </Select>
                        <p class="">{{ 'mergejliin_chigleleer' | translate }}</p>
                        <Select class="job_select" v-model="selectSalbar" :placeholder="'chiglel_songoh' | translate" filterable>
                            <Option v-for="(salbar, index) in ds_mergejil_ded_salbar_view" :value="salbar.id" :key="index">
                                {{ salbar.ded_salbar }}
                            </Option>
                        </Select>
                        <p class="">{{ 'mergejil' | translate }}</p>
                        <Select class="job_select" v-model="selectMergejil" :placeholder="'mergejil_songoh' | translate" filterable>
                            <Option v-for="(mergejil, index) in Mergejiluud" :value="mergejil.id" :key="index">
                                {{ mergejil.mergejil }}
                            </Option>
                        </Select>
                        <p>{{ 'tsagiin_turul' | translate }}</p>
                        <Select class="job_select" v-model="selectTsagiinTurul"
                                :placeholder="'tsagiin_turul_songoh' | translate">
                            <Option v-for="(turul, index) in ds_tsag_view" :value="turul.id" :key="index">
                                {{ turul.tsagiin_torol }}
                            </Option>
                        </Select>

                        <p>{{ 'aimag_niislel' | translate }}</p>
                        <Select class="job_select" v-model="selectAimagNiislelBrgd"
                                on-select="getSumDuuregBrgd"
                                :placeholder="'aimagcapitalcity'  | translate ">
                            <Option v-for="(item, index) in aimag_niislelBrgd" :value="item.id" :key="index">
                                {{ item.aimagname }}
                            </Option>
                        </Select>


                      <p>{{ 'sum_duureg' | translate }}</p>
                      <Select class="job_select1" v-model="selectSumDuuregBrgd"
                              :placeholder="'sumdistrict' | translate">
                        <Option v-for="(item, index) in SumDvvreg" :value="item.id" :key="index">
                          {{ item.sumname }}
                        </Option>
                      </Select>
                    </div>

                    <div class="search">
                        <Button @click="callDataBrigad" class="search_btn">
                            <i class="ivu-icon ivu-icon-ios-search"></i>
                            {{ 'search' | translate }}
                        </Button>
                    </div>
                </div>

                <!--banner-->
                <div class="c_banner" >
                    <a v-if="banner2.unelgee"><img :src="IMAGE_URL + banner2.unelgee" alt="Image"></a>
                </div>
            </div>
            <!--Right Slide and table list-->
            <div class="col-md-8">
                <div class="right">
                    <div class="top_item">
                         <brigad_slide></brigad_slide>
                    </div>
                    <!--Right table list (Ажлын зарын лист)-->
                    <div class="p_table" v-if="brigad_ajiliin_zar.length > 0">
                        <table width="100%">
                            <tbody>
                            <!--tr-->
                            <tr v-for="(zar, index1) in brigad_ajiliin_zar" :key="`${index1}-${zar.id}`">
                                <td >
                                    <!--                  <div class="company_logos">-->
                                    <!--                    <img src="../../assets/company_logo/1_gold.svg" alt="">-->
                                    <!--                  </div>-->
                                    <div class="company_logos" v-if="zar.gishuun">
                                        <img class="logo" src="../../assets/company_logo/1_gold.svg" alt=""
                                             v-if="zar.gishuun === 'Алтан багц'">
                                        <img class="logo" src="../../assets/company_logo/1_silver.svg" alt=""
                                             v-if="zar.gishuun === 'Мөнгөн багц'">
                                        <img class="logo" src="../../assets/company_logo/1_bronze.svg" alt=""
                                             v-if="zar.gishuun === 'Хүрэл багц'">
                                    </div>
                                    <div class="company_logos" v-else>
                                        <img class="logo" src="../../assets/company_logo/2_gold.svg" alt=""
                                             v-if="zar.gishuun_bus === 'Алтан багц'">
                                        <img class="logo" src="../../assets/company_logo/2_silver.svg" alt=""
                                             v-if="zar.gishuun_bus === 'Мөнгөн багц'">
                                        <img class="logo" src="../../assets/company_logo/2_bronze.svg" alt=""
                                             v-if="zar.gishuun_bus === 'Хүрэл багц'">
                                    </div>
                                    <div class="p_logo">
                                        <img :src="IMAGE_URL + zar.logo" alt="" v-if="zar.logo != ''">
                                        <img src="../../assets/no-image.png" alt="" v-else>
                                    </div>
                                </td>
                                <td>
                                    <div class="col_1 ">
                                        <router-link :to="`/br_a_b_zar_details/${zar.id}`"><h5>{{ zar.mergejil }}</h5></router-link>
                                        <div class="flex_col">
                                            <div class="sub_col_2">
                                                <span class="company_n">{{ zar.brigad_ner }} </span>
                                                <div class="text_bold">{{ 'where' | translate }}: <span
                                                        class="not_bold"> {{ zar.aimagname }} </span>
                                                </div>
                                            </div>
                                            <div class="static_button">
                                                {{ zar.tsagiin_torol }}
                                            </div>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                  <save_button_brigad_ajil_bair_zar :zar_id="zar.id" type="brigad"></save_button_brigad_ajil_bair_zar>
                                    <div class="td_3">
                                        <div class="td_3_1">
                                            <p>{{ 'zarlasan_ognoo' | translate }}</p>
                                            <p class="diff_p_text">{{ zar.e_ognoo | date }}</p>
                                        </div>
                                        <div class="td_3_2">
                                            <p>{{ 'anket_avah_ognoo' | translate }}</p>
                                            <p class="p_text ">{{ zar.d_ognoo | date}}</p>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                      <div class="page">
                        <Page @on-change="changePage"
                              :current-page="current_page1"
                              :page-size="10"
                              :total="total"
                              size="small"
                        />
                      </div>
                    </div>
                  <div v-else class="no-data">
                      {{ 'no_ads' | translate }}
                  </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

import {mapGetters} from "vuex";
import {
  GET_BRGD_AJIL_BAIR_ZAR_PAGE_BY_ID,
  GET_BRIGAD_VIEW,
  GET_TSAGIIN_TURUL,
  GET_TSALIN_TURUL,
  GET_MERGEJIL_DED_SALBAR,
  GET_MERGEJIL,
  IMAGE_URL,
  GET_BRGD_AJIL_BAIR_ZAR_PAGE,
  GET_BANNER_VIEW, GET_SUM_DUUREG_ALL, GET_AIMAG_NIISLEL,
} from "../../graphql/queries";
  const save_button_brigad_ajil_bair_zar = () => import(/* webpackChunkName: "save_button_brigad_ajil_bair_zar" */ '../section/save_button_brigad_ajil_bair_zar');
  const brigad_slide = () => import(/* webpackChunkName: "brigad_slide" */ '../section/brigad_slide');
export default {
    name: "workplaces_ad",
    components: {
       "save_button_brigad_ajil_bair_zar": save_button_brigad_ajil_bair_zar,
       "brigad_slide": brigad_slide,
    },
    data() {
        return {
            select_ds_brigad_view: [],
            ds_tsalin_view: [],
            ds_tsag_view: [],
            ds_mergejil_ded_salbar_view: [],
            ds_mergejil_lut_view: [],
            loading: true,
            IMAGE_URL: IMAGE_URL,
            filtered_mergejil: [],
            selectMergejil: '',
            selectBrigad: '',
            selectTsagiinTurul: '',
            selectTsalinTurul: '',
            selectSalbar: null,
            brigad_ajiliin_zar: [],
            total: 0,
            current_page1: 1,
            last_page: 1,
            banner2: {},

            selectAimagNiislelBrgd: '',
            selectSumDuuregBrgd: '',
            aimag_niislelBrgd: [],
            sumduuregBrgd: [],
        }
    },
    computed: {
      ...mapGetters([
            'user',
            'languages',
            'language',
            // ...
      ]),
      Mergejiluud() {
        if (this.selectSalbar) {
          return this.ds_mergejil_lut_view.filter(mergejil => mergejil.mergejil_ded_id == this.selectSalbar);
        } else {
                return this.ds_mergejil_lut_view;
        }
      },
      SumDvvreg(){
        if (this.selectAimagNiislelBrgd) {
          return this.sumduuregBrgd.filter(sumdvvreg => sumdvvreg.aimagid == this.selectAimagNiislelBrgd);
        } else {
          return this.sumduuregBrgd;
        }
      },
    },
    mounted() {
        this.getTsagiinTurul();
        this.getTsalinTurul();
        this.getSalbar();
        this.getMergejil();
        this.callDataBrigad();
        this.getBanner();
        this.getBrigad_select();
        this.getAimagNiislelBrgd();
        this.getSumDuuregBrgd();
    },
    methods: {
        getBrigad_select() {
            this.$apollo.query({query: GET_BRIGAD_VIEW}).then(({data}) => {
                this.select_ds_brigad_view = data.ds_brilgas_view;
            })
        },
        getTsagiinTurul() {
            this.$apollo.query({query: GET_TSAGIIN_TURUL}).then(({data}) => {
                this.ds_tsag_view = data.ds_tsag_view;
            })
        },
        getTsalinTurul() {
            this.$apollo.query({query: GET_TSALIN_TURUL}).then(({data}) => {
                this.ds_tsalin_view = data.ds_tsalin_view;
            })
        },
        getSalbar() {
            this.$apollo.query({query: GET_MERGEJIL_DED_SALBAR}).then(({data}) => {
                this.ds_mergejil_ded_salbar_view = data.ds_mergejil_ded_salbar_view;
            })
        },
        getMergejil() {
            this.$apollo.query({query: GET_MERGEJIL}).then(({data}) => {
                this.ds_mergejil_lut_view = data.ds_mergejil_lut_view;
            })
        },
        cleanFilter() {
          this.selectBrigad =  '';
          this.selectMergejil =  '';
          this.selectTsagiinTurul =  '';
          this.selectTsalinTurul =  '';
          this.selectAimagNiislelBrgd =  '';
          this.selectSumDuuregBrgd =  '';
          this.callDataBrigad();
        },
        callDataBrigad() {
          this.loading = true;
            if (this.selectBrigad || this.selectTsagiinTurul || this.selectTsalinTurul || this.selectMergejil || this.selectAimagNiislelBrgd || this.selectSumDuuregBrgd) {
                this.$apollo.query({
                    query: GET_BRGD_AJIL_BAIR_ZAR_PAGE_BY_ID, fetchPolicy: 'no-cache',
                    variables: {
                        page: this.current_page1, size: 10,
                        brigad_id: this.selectBrigad.toString(),
                        tsag_id: this.selectTsagiinTurul.toString(),
                        tsalin_id: this.selectTsalinTurul.toString(),
                        mergejil_id: this.selectMergejil.toString(),
                        aimag_niislel: this.selectAimagNiislelBrgd.toString(),
                        sum_duureg: this.selectSumDuuregBrgd.toString(),
                    }
                }).then(({data}) => {
                    this.brigad_ajiliin_zar = data.paginate.ds_brigad_ajilin_bair_zar_view;
                    this.total = data.paginate.total;
                    setTimeout(() => {
                      this.loading = false;
                    })
                })
            } else {
                this.loading = true;
                this.$apollo.query({
                    query: GET_BRGD_AJIL_BAIR_ZAR_PAGE, fetchPolicy: 'no-cache',
                    variables: {page: this.current_page1, size: 10,}
                }).then(({data}) => {
                    this.brigad_ajiliin_zar = data.paginate.ds_brigad_ajilin_bair_zar_view;
                    this.total = data.paginate.total;
                    setTimeout(() => {
                       this.loading = false;
                    })
                })
            }
        },
        changePage(page) {
            this.current_page1 = page;
            this.callDataBrigad();
        },
        getBanner() {
            this.loading = true;
            this.$apollo.query({query: GET_BANNER_VIEW}).then(({data}) => {
                this.banner2 = data.ds_banner_view[1];
                this.loading = false;
            })
        },
      getAimagNiislelBrgd(){
        this.$apollo.query({ query: GET_AIMAG_NIISLEL}).then(({data}) => {
          this.aimag_niislelBrgd = data.ds_aimag_view;
        })
      },
      SumDvvregBrgd(){
        if (this.selectAimagNiislelBrgd) {
          return this.sumduuregBrgd.filter(sumdvvreg => sumdvvreg.aimagid == this.selectAimagNiislelBrgd);
        } else {
          return this.sumduuregBrgd;
        }
      },
      getSumDuuregBrgd(){
        this.$apollo.query({query: GET_SUM_DUUREG_ALL}).then(({data}) => {
          this.sumduuregBrgd = data.ds_sum_view;
        })
      },

    }
}
</script>

<style scoped>

</style>
